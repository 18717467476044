import React from "react"

/** Components */
import SEO from "../components/seo"
import Block from "@components/Block"
import Layout from "@components/layout"
import ShareBar from "@components/ShareBar"
import TitleCard from "@components/TitleCard"
import TextBlock from "@components/TextBlock"
import Navigation from "@components/Navigation"
import IntroBlock from "@components/IntroBlock"
import PromoBlock from "@components/PromoBlock"
import PivotBlock from "@components/PivotBlock"
import BulletBlock from "@components/BulletBlock"
import CheckboxBlock from "@components/CheckboxBlock"

/** Helpers */
import TextBlockQuery from "../helpers/query-text-block"
import TitleBlockQuery from "../helpers/query-title-block"
import IntroBlockQuery from "../helpers/query-intro-block"
import PivotBlockQuery from "../helpers/query-pivot-block"
import BulletBlockQuery from "../helpers/query-bullet-block"
import CheckboxBlockQuery from "../helpers/query-checkbox-block"

const BoldPivotsPage = () => {
  const editionIdentifier = "allMakeshiftData"
  const textBlockData = TextBlockQuery(editionIdentifier)
  const introBlockData = IntroBlockQuery(editionIdentifier)
  const titleBlockData = TitleBlockQuery(editionIdentifier)
  const pivotBlockData = PivotBlockQuery(editionIdentifier)
  const bulletBlockData = BulletBlockQuery(editionIdentifier)
  const checkboxBlockData = CheckboxBlockQuery(editionIdentifier)
  console.log(titleBlockData)

  return (
    <Layout>
      <Navigation />
      <SEO title="Bold Pivots" description="A brand-new, monthly briefing to help you navigate this wild New Normal." />
      <ShareBar shareUrl="https://makeshift.trendwatching.com/bold-pivots/" />

      {/* intro section */}
      <div className="columns is-gapless is-multiline">
        <div className="column is-one-third">
          <IntroBlock {...introBlockData[0]} />
        </div>
        <div className="column is-one-third">
          <Block background={titleBlockData[0].background_colour}>
            <TitleCard
              title={titleBlockData[0].title}
              eyebrow={titleBlockData[0].eyebrow}
              tagline={titleBlockData[0].tagline}
              image="arrows-down.group"
            />
          </Block>
        </div>
        <div className="column is-one-third">
          <Block background={textBlockData[0].background_colour}>
            <TextBlock {...textBlockData[0]} />
          </Block>
        </div>
      </div>

      {/* setting the scene section */}
      <div className="columns is-gapless is-multiline">
        <div className="column is-one-third">
          <Block background={titleBlockData[1].background_colour}>
            <TitleCard
              title={titleBlockData[1].title}
              eyebrow={titleBlockData[1].eyebrow}
              tagline={titleBlockData[1].tagline}
            />
          </Block>
        </div>
        {bulletBlockData.map((bullet, index) => {
          return (
            <div className="column is-one-third" key={index}>
              <Block background={index % 2 || index > 3 ? "red" : "pink"} key={index}>
                <BulletBlock key={index} {...bullet} />
              </Block>
            </div>
          )
        })}
        <div className="column is-one-third">
          <Block background="teal">
            <TitleCard
              title={titleBlockData[2].title}
              eyebrow={titleBlockData[2].eyebrow}
              tagline={titleBlockData[2].tagline}
            />
          </Block>
        </div>
        <div className="column is-one-third">
          <Block background="teal">
            <TextBlock {...textBlockData[1]} />
          </Block>
        </div>
      </div>

      {/* pivots in action section */}
      {pivotBlockData.map((Pivot) => {
        return <PivotBlock key={Pivot.order} {...Pivot} />
      })}

      {/* over to you section */}
      <div className="columns is-gapless is-multiline">
        <div className="column is-one-third">
          <Block background={titleBlockData[3].background_colour}>
            <TitleCard
              title={titleBlockData[3].title}
              eyebrow={titleBlockData[3].eyebrow}
              tagline={titleBlockData[3].tagline}
            />
          </Block>
        </div>
        <div className="column is-one-third">
          <Block background={textBlockData[2].background_colour}>
            <TextBlock {...textBlockData[2]} />
          </Block>
        </div>

        {/* Checklists */}
        {checkboxBlockData.map((item, index) => {
          return (
            <div className="column is-one-third" key={index}>
              <Block background="blonde">
                <CheckboxBlock {...item} order={index} />
              </Block>
            </div>
          )
        })}

        <div className="column is-one-third">
          <div style={{ backgroundColor: "rgb(249, 245, 166)", padding: 0, position: "relative", height: "100%" }}>
            <div
              className="background-split"
              style={{ background: "linear-gradient(to right bottom, #ffffff00 50%, #FF6050 50%)", height: "100%" }}
            ></div>
          </div>
        </div>
      </div>

      {/* Outro section */}
      <div className="columns is-gapless is-multiline">
        <div className="column is-one-third">
          <IntroBlock {...introBlockData[1]} />
        </div>
        <div className="column is-one-third">
          <PromoBlock
            isPreviousEditions
            bgColor="F4EB4E"
            buttonUrl="https://trendwatching.com/makeshift"
            sectionTitle="Here's the opportunities you may have missed."
          />
        </div>
        <div className="column is-one-third">
          <PromoBlock
            buttonUrl="#"
            isSubscribeSection
            bgColor="F4EB4E"
            sectionTitle="Get opportunities that matter. Monthly. Always."
            formCopy="Join 100,000+ future-focused professionals in 180 countries already receiving our free trend updates."
          />
        </div>
      </div>
    </Layout>
  )
}

export default BoldPivotsPage
