import { graphql, useStaticQuery } from "gatsby"
import FilterACFData from "../helpers/filterAcfData"

const PivotBlockQuery = (editionIdentifier) => {
  const queryData = useStaticQuery(graphql`
    query {
      allMakeshiftData {
        nodes {
          acf {
            makeshift_modules {
              acf_fc_layout
              backgroundImageUrl
              bodyCopy
              title
              order
            }
          }
        }
      }
    }
  `)

  const filteredData = FilterACFData(queryData[editionIdentifier], "pivot_block")

  return filteredData
}

export default PivotBlockQuery
