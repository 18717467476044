import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
  WhatsappIcon,
} from "react-share"

import "./ShareBar.scss"

const ShareBar = ({ shareUrl, isVisible }) => {
  const [scrolled, setScrolled] = useState(false)

  /* Simple effect for listening to the window scroll position, updating the
   ** state and the using that value to show or hide the sharebar. */
  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 500
      if (isScrolled !== scrolled) {
        setScrolled(!scrolled)
      }
    }

    document.addEventListener("scroll", handleScroll, { passive: true })

    return () => {
      document.removeEventListener("scroll", handleScroll)
    }
  }, [scrolled])

  return (
    <div className="share-bar" data-active={scrolled}>
      <FacebookShareButton url={shareUrl}>
        <span className="icon-wrap icon-facebook">
          <FacebookIcon size={48} />
        </span>
      </FacebookShareButton>
      <LinkedinShareButton url={shareUrl}>
        <span className="icon-wrap icon-linkedin">
          <LinkedinIcon size={48} />
        </span>
      </LinkedinShareButton>
      <TwitterShareButton url={shareUrl}>
        <span className="icon-wrap icon-twitter">
          <TwitterIcon size={48} />
        </span>
      </TwitterShareButton>
      <WhatsappShareButton url={shareUrl}>
        <span className="icon-wrap icon-whatsapp">
          <WhatsappIcon size={48} />
        </span>
      </WhatsappShareButton>
    </div>
  )
}

ShareBar.propTypes = {
  shareUrl: PropTypes.string,
  isVisible: PropTypes.bool,
}

export default ShareBar
