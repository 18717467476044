import React from "react"
import PropTypes from "prop-types"

import BackgroundImage from "../../helpers/background-image"

import ImagePieChart from "@images/pie-pivot-chart.svg"
import ImagePivot1 from "@images/pie-pivot-1.svg"
import ImagePivot2 from "@images/pie-pivot-2.svg"
import ImagePivot3 from "@images/pie-pivot-3.svg"
import ImagePivot4 from "@images/pie-pivot-4.svg"
import ImagePivot5 from "@images/pie-pivot-5.svg"
import ImagePivot6 from "@images/pie-pivot-6.svg"
import ImagePivot7 from "@images/pie-pivot-7.svg"
import "./PivotBlock.scss"

const PivotBlock = ({ title, backgroundImageUrl, bodyCopy, order }) => {
  const pivotImage = getImage(parseInt(order))

  return (
    <div className="columns is-gapless is-multiline">
      <div className="column is-one-third is-hidden-mobile">
        <BackgroundImage filename={backgroundImageUrl} className="pivot-image" loading="eager" alt="pivot image" />
      </div>

      <div className="column is-one-third">
        <div className="title-container">
          <BackgroundImage
            filename={backgroundImageUrl}
            loading="eager"
            className="pivot-image-mobile"
            alt="pivot image mobile"
          />
          <div className="background-split"></div>
          <div className="pie-chart">
            <img className="pivot-point" src={pivotImage} alt="" />
            <img src={ImagePieChart} alt="" />
          </div>
          <div className="pivot-title">
            <h2 className="pivot-order">{order}.</h2>
            <h3>{title}</h3>
          </div>
        </div>
      </div>

      <div className="column is-one-third">
        <div className="pivot-content">
          <p dangerouslySetInnerHTML={{ __html: bodyCopy }} />
        </div>
      </div>
    </div>
  )
}

const getImage = (order) => {
  switch (order) {
    case 1:
      return ImagePivot1
    case 2:
      return ImagePivot2
    case 3:
      return ImagePivot3
    case 4:
      return ImagePivot4
    case 5:
      return ImagePivot5
    case 6:
      return ImagePivot6
    case 7:
      return ImagePivot7
    default:
      return null
  }
}

PivotBlock.propTypes = {
  title: PropTypes.string,
  backgroundImageUrl: PropTypes.string,
  bodyCopy: PropTypes.string,
  order: PropTypes.string,
}

export default PivotBlock
